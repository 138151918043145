import React from 'react';

import commodities from '../../assets/Benefits/commodities.svg';
import dollar from '../../assets/Benefits/dollar.svg';
import favorite from '../../assets/Benefits/favorite.svg';
// import orders from '../../assets/Benefits/orders.svg';
import tips from '../../assets/Benefits/tips.svg';
import weather from '../../assets/Benefits/weather.svg';
import Carousel from './Carousel';
import {
  PageContainer,
  Header,
  FirstPartTitle,
  SecondPartTitle,
  Description,
  Container,
} from './Benefits_style';

const Benefits = () => {
  // Items array must have duplicated items to display an infinite carousel
  const items = [
    {
      id: 1,
      title: 'Seus Favoritos',
      icon: favorite,
      borderType: 1,
      text: 'Além de consultar todas as culturas do nosso sistema, você pode favoritar as que são de sua preferência para consultá-las facilmente.',
    },
    {
      id: 2,
      title: 'Commodities',
      icon: commodities,
      borderType: 2,
      text: 'Tenha na palma da sua mão os valores, variação e praças das suas culturas de interesses',
    },
    {
      id: 3,
      title: 'Cotação do Dólar',
      icon: dollar,
      borderType: 3,
      text: 'Consulte a cotação a qualquer momento e receba diariamente informações do dólar como o último fechamento, valor de compra e venda, variação diária e a cotação do Ptax.',
    },
    // Temporarily removing Orders because of LGPD issues
    // {
    //   id: 4,
    //   title: 'Seus Pedidos',
    //   icon: orders,
    //   borderType: 4,
    //   text: 'Tenha em mãos as informações sobre data de vencimento, valor a pagar, receba atualizações referentes ao status de faturamento dos seus pedidos, e muito mais.',
    // },
    {
      id: 4,
      title: 'Dicas',
      icon: tips,
      borderType: 5,
      text: 'Receba dicas personalizadas sobre suas culturas, momentos do cultivo e muito mais.',
    },
    {
      id: 5,
      title: 'Previsão do Tempo',
      icon: weather,
      borderType: 5,
      text: 'Favorite sua cidade para consultar e receber a previsão do tempo com dados de temperatura e precipitação esperada diária e semanal para a sua localização. ',
    },
    {
      id: 6,
      title: 'Seus Favoritos',
      icon: favorite,
      borderType: 1,
      text: 'Além de consultar todas as culturas do nosso sistema, você pode favoritar as que são de sua preferência para consultá-las facilmente.',
    },
    {
      id: 7,
      title: 'Commodities',
      icon: commodities,
      borderType: 2,
      text: 'Tenha na palma da sua mão os valores, variação e praças das suas culturas de interesses',
    },
    {
      id: 8,
      title: 'Cotação do Dólar',
      icon: dollar,
      borderType: 3,
      text: 'Consulte a cotação a qualquer momento e receba diariamente informações do dólar como o último fechamento, valor de compra e venda, variação diária e a cotação do Ptax.',
    },
    {
      id: 9,
      title: 'Dicas',
      icon: tips,
      borderType: 5,
      text: 'Receba dicas personalizadas sobre suas culturas, momentos do cultivo e muito mais.',
    },
    {
      id: 10,
      title: 'Previsão do Tempo',
      icon: weather,
      borderType: 5,
      text: 'Favorite sua cidade para consultar e receber a previsão do tempo com dados de temperatura e precipitação esperada diária e semanal para a sua localização. ',
    },
  ];

  return (
    <PageContainer id="benefits">
      <Header>
        <FirstPartTitle>
          <span>BENE</span>
        </FirstPartTitle>
        <SecondPartTitle>
          <span>FÍCIOS</span>
        </SecondPartTitle>
      </Header>
      <Description>
        Conheça os múltiplos benefícios que o app Goplan pode te oferecer
      </Description>
      <Container>
        <Carousel items={items} />
      </Container>
    </PageContainer>
  );
};

export default Benefits;
