import {
  Container,
  FooterContentBox,
  SocialMediaIcons,
  AuthBox,
  AuthText,
  Version,
  AuthLink,
  AuthUl,
  AuthLi,
  LogoImg,
  ConectUs,
  InstagramSvg,
  NavLinks,
  AuthLastText,
} from './Footer_style.js';

import FacebookIcon from '../../assets/Footer/FacebookIcon.svg';
import InstagramIcon from '../../assets/Footer/instagram.svg';
import LinkedinIcon from '../../assets/Footer/linkedin.svg';
import GoplanLogo from '../../assets/Footer/GoplanLogo.svg';
import packageInfo from '../../../package.json';

const Footer = () => {
  return (
    <Container>
      <FooterContentBox>
        <AuthBox>
          <ConectUs>
            <LogoImg src={GoplanLogo} alt="Logo goplan" />
            <AuthText>Conecte-se conosco</AuthText>
            <SocialMediaIcons>
              <img
                src={FacebookIcon}
                alt="Facebook icon"
                onClick={() =>
                  window.open(process.env.REACT_APP_FACEBOOK, '_blank')
                }
              />
              <InstagramSvg
                src={InstagramIcon}
                alt="Instagram icon"
                onClick={() =>
                  window.open(process.env.REACT_APP_INSTAGRAM, '_blank')
                }
              />
              <img
                src={LinkedinIcon}
                alt="Linkedin icon"
                onClick={() =>
                  window.open(process.env.REACT_APP_LINKEDIN, '_blank')
                }
              />
            </SocialMediaIcons>
          </ConectUs>
          <NavLinks>
            <AuthUl>
              <AuthLi>
                <AuthLink href="https://goplan.com.br/quem-somos">
                  Quem somos
                </AuthLink>
              </AuthLi>
              <AuthLi>
                <AuthLink href="https://goplan.com.br/#solucoes">
                  Soluções
                </AuthLink>
              </AuthLi>
              <AuthLi>
                <AuthLink href="https://goplan.com.br/#parceiros">
                  Parceiros
                </AuthLink>
              </AuthLi>
              <AuthLi>
                <AuthLink href="https://goplan.com.br/#formulario-franqueado">
                  Seja um franqueado
                </AuthLink>
              </AuthLi>
              <AuthLi>
                <AuthLink href="https://goplan.com.br/#formulario-contato">
                  Contato
                </AuthLink>
              </AuthLi>
            </AuthUl>
            <hr />
            <AuthLastText href="https://goplan.com.br/politica-de-privacidade/">
              PROTEÇÃO E PRIVACIDADE DE DADOS
            </AuthLastText>
          </NavLinks>
        </AuthBox>

        {/* CURRENT VERSION */}
        {/* TODO: MUST TO BE CHANGED ON EACH RELEASE */}
        <Version>Versão: {packageInfo.version} {process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ''}</Version>
      </FooterContentBox>
    </Container>
  );
};

export default Footer;
