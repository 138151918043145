import { useState } from 'react';

import {
  PageContainer,
  Header,
  HeaderWeb,
  Logo,
  ButtonMenu,
  DropdownMenu,
  CardLogIn,
  ButtonLogIn,
} from './Menu_style';

import logoGoplan from '../../assets/Menu/logo-goplan.svg';
import logoGoplanWhite from '../../assets/Menu/logo-goplan-branco.svg';
import close from '../../assets/Menu/x.svg';
import alignJustify from '../../assets/Menu/align-justify.svg';

const Menu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleScrollMenu = (type) => {
    window.location.replace(`/#${type}`);
    setIsOpenMenu(!isOpenMenu);
  };

  const handleScrollMenuWeb = (type) => {
    window.location.replace(`/#${type}`);
  };

  return (
    <PageContainer id="menu" isOpen={isOpenMenu}>
      <Header>
        <Logo src={logoGoplan} alt="logo goplan" />
        <ButtonMenu onClick={handleOpenMenu}>
          <img src={alignJustify} alt="icon-menu" />
        </ButtonMenu>
      </Header>
      <HeaderWeb>
        <li onClick={() => handleScrollMenuWeb('benefits')}>
          <span>Benefícios</span>
        </li>
        <li onClick={() => handleScrollMenuWeb('notifications')}>
          <span>Notificações</span>
        </li>
        <li onClick={() => handleScrollMenuWeb('advantages')}>
          <span>O Aplicativo</span>
        </li>
      </HeaderWeb>
      <DropdownMenu isOpen={isOpenMenu}>
        <Header>
          <Logo
            src={isOpenMenu ? logoGoplanWhite : logoGoplan}
            isOpen={isOpenMenu}
            alt="logo goplan"
          />
          <ButtonMenu onClick={handleOpenMenu} isOpen={isOpenMenu}>
            <img src={isOpenMenu ? close : alignJustify} alt="icon-menu" />
          </ButtonMenu>
        </Header>
        <li onClick={() => handleScrollMenu('benefits')}>
          <span>Benefícios</span>
        </li>
        <li onClick={() => handleScrollMenu('notifications')}>
          <span>Notificações</span>
        </li>
        <li onClick={() => handleScrollMenu('advantages')}>
          <span>O Aplicativo</span>
        </li>
        <CardLogIn>
          <ButtonLogIn
            type="button"
            onClick={() => window.open(process.env.REACT_APP_HOME, '_blank')}
            className="bnt-login"
          >
            Entrar
          </ButtonLogIn>
        </CardLogIn>
      </DropdownMenu>
    </PageContainer>
  );
};

export default Menu;
