import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const Container = styled.div`
  position: relative;
  margin-top: 67px;

  @media ${device.laptop} {
    margin-top: 300px;
  }
`;

export const MapBox = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${GlobalColors.Primary.yellow};

  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    position: relative;
    align-items: flex-start;
    height: 522px;
  }
`;

export const CountryImg = styled.img`
  width: 315px;
  margin: 59px auto 36px auto;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    width: 40%;
    margin: unset;
    margin-top: 44px;
    margin-right: 28px;
    margin-left: 32px;
    max-width: 380px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    padding-left: 5%;
    width: 60%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const MapContent = styled.span`
  width: 274px;
  ${GlobalFontStyle.H6.regular};
  text-align: center;
  margin: 0px 27px 30px 19px;
  color: ${GlobalColors.SupportColors.pureWhite};

  @media ${device.laptop} {
    margin: 47px 0px 47px 0px;
    width: 90%;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Toggle = styled.div`
  ${GlobalFontStyle.H3.semibold};
  background-color: ${GlobalColors.Primary.yellow};
  color: ${GlobalColors.SupportColors.pureWhite};
  width: 190px;
  height: 134px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -67px;
  left: calc(50% - 95px);
  z-index: -1;
  padding-top: 24px;

  @media ${device.laptop} {
    ${GlobalFontStyle.T3.semibold};
    width: 403px;
    height: 282px;
    top: -141px;
    left: calc(50% - 201px);
    padding-top: 54px;
  }
`;

export const BrandsArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 50px);
  grid-template-columns: repeat(6, auto);
  row-gap: 28px;
  column-gap: 16px;
`;

export const Brand = styled.img`
  width: 100%;
  max-width: 200px;
`;
