// Based on https://codepen.io/onion2k/pen/xxZYBVj
import { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
  Card,
  CardTitle,
  IconsArea,
  Icon,
  TextArea,
} from './Benefits_style.js';
import './carousel.css';

const BenefitsCarousel = ({ items, active }) => {
  const itemsToShow = items;
  const [itemsNumber, setItemsNumber] = useState(4);

  const resCarousel = () => {
    if (window.innerWidth <= 320) setItemsNumber(1);
    else if (window.innerWidth <= 375) setItemsNumber(1);
    else if (window.innerWidth <= 768) setItemsNumber(2);
    else if (window.innerWidth <= 1024) setItemsNumber(3);
    else if (window.innerWidth <= 1440) setItemsNumber(4);
    else setItemsNumber(4);
    console.log(window.innerWidth);
  };

  window.addEventListener('resize', resCarousel);

  return (
    <section id="benefits-section">
      <OwlCarousel
        className="owl-theme"
        center
        loop
        margin={20}
        autoplay
        autoplayTimeout={5000}
        autoplayHoverPause
        items={itemsNumber}
      >
        {itemsToShow.map((item) => (
          <div className="item">
            <Card
              key={item.id}
              className="item-benefits"
              borderType={item.borderType}
            >
              <CardTitle>{item.title}</CardTitle>
              <IconsArea borderType={item.borderType}>
                <Icon src={item.icon} alt={item.title} />
              </IconsArea>
              <TextArea borderType={item.borderType}>{item.text}</TextArea>
            </Card>
          </div>
        ))}
      </OwlCarousel>
    </section>
  );
};

export default BenefitsCarousel;
