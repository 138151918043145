import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const PageContainer = styled.div`
  background: linear-gradient(
    280deg,
    rgba(72, 178, 34, 0.14) 0%,
    rgba(91, 178, 34, 0) 100%
  );
  position: relative;
  margin-bottom: 47px;

  @media ${device.laptop} {
    background: ${GlobalColors.SupportColors.pureWhite};
    :after {
      content: '';
      width: 93%;
      height: 100%;
      position: absolute;
      opacity: 0.6;
      background: linear-gradient(
        250.39deg,
        rgba(72, 178, 34, 0.14) 0.53%,
        rgba(91, 178, 34, 0) 141.78%
      );
      filter: blur(30px);
      top: 0px;
      left: 40px;
    }
    margin-bottom: 200px;
  }
`;

export const NewAbout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${device.laptop} {
    display: grid;
  }

  span {
    ${GlobalFontStyle.H2.semibold}
    text-align: center;
    color: ${GlobalColors.Primary.azulPrimario};
    margin: 21px 30px 0px 36px;
    @media ${device.laptop} {
      grid-column: 2;
      grid-row: 2;
      ${GlobalFontStyle.T3.semibold}
      text-align: right;
    }

    @media ${device.laptopL} {
      margin-right: 130px;
    }
  }

  img {
    margin: 31px 0px;
    @media ${device.laptop} {
      grid-column: 1;
      grid-row: span 4;
      z-index: 1;
    }

    @media ${device.laptopL} {
      width: 615px;
      height: 550px;
      grid-row: span 5;
      margin-left: 100px;
    }
  }

  .bannerMobile {
    @media ${device.laptop} {
      display: none;
    }
  }

  .bannerDesktop {
    @media ${device.mobileS} {
      display: none;
    }

    @media ${device.laptop} {
      display: flex;
    }
  }

  p {
    ${GlobalFontStyle.H5.regular}
    color: ${GlobalColors.Primary.azulPrimario};
    text-align: center;
    margin: 0px 20px 25px 26px;
    @media ${device.laptop} {
      grid-column: 2;
      grid-row: 3;
      text-align: right;
      justify-self: end;
      width: 380px;
      margin: 41px 30px 41px 0px;
      ${GlobalFontStyle.H3.regular}
    }

    @media ${device.laptopL} {
      margin-right: 130px;
    }
  }

  button {
    color: ${GlobalColors.SupportColors.pureWhite};
    background-color: ${GlobalColors.Primary.verdePrimario};
    ${GlobalFontStyle.H5.semibold}
    height: 39px;
    width: 171px;
    border: none;
    padding: 2px, 22px, 2px, 22px;
    margin-bottom: 100px;
    cursor: pointer;

    @media ${device.laptop} {
      z-index: 1;
      ${GlobalFontStyle.H3.semibold}
      grid-column: 2;
      grid-row: 4;
      justify-self: end;
      height: 50px;
      width: 240px;
      margin-right: 30px;
    }

    @media ${device.laptopL} {
      margin-right: 130px;
    }
  }
`;

export const ImgIcon = styled.img`
  right: 80%;
  bottom: 540px;
  position: absolute;
  background: linear-gradient(
    360deg,
    rgba(91, 178, 34, 0.234) -5%,
    rgba(91, 178, 34, 0.0667554) 58.66%,
    rgba(91, 178, 34, 0) 92.94%
  );
  border-radius: 35px;

  @media ${device.mobileM} {
    top: 600px;
  }

  @media ${device.mobileL} {
    top: 610px;
    right: 85%;
  }

  @media ${device.tablet} {
    top: 550px;
    right: 90%;
  }

  @media ${device.laptop} {
    width: 70px;
    height: 70px;
    margin-left: 27px;
    top: 740px;
  }

  @media ${device.laptopL} {
    width: 80px;
    height: 80px;
    margin-left: 100px;
    top: 800px;
  }

  @media ${device.desktop} {
    margin-left: 215px;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 23px;
  margin-top: 49px;
  justify-content: center;
  height: 22px;

  @media ${device.laptop} {
    height: 54px;
    margin-top: 100px;
  }
`;

export const ContentContainer = styled.div`
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FirstPartTitle = styled.div`
  color: ${GlobalColors.Primary.verdePrimario};
  ${GlobalFontStyle.H3.semibold};
  display: flex;
  width: 8%;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 0.5px solid;
  margin: 0px 1px 0px 41%;

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
    width: 6.5%;
    border-bottom: 3px solid;
  }
`;

export const SecondPartTitle = styled.div`
  color: ${GlobalColors.SupportColors.pureWhite};
  background-color: ${GlobalColors.Primary.azulPrimario};
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1px;
  ${GlobalFontStyle.H3.semibold};

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
    width: 53%;
  }
`;

export const Description = styled.div`
  @media ${device.mobileS} {
    .desc-web {
      display: none;
    }
  }

  @media ${device.laptop} {
    .desc-web {
      align-items: center;
      display: flex;
      margin-left: 77px;
      width: 348px;
      margin-top: 190px;
    }

    .desc-mobile {
      display: none;
    }
  }

  p {
    ${GlobalFontStyle.H5.regular};
    text-align: center;
    margin-left: 26px;
    margin-right: 32px;
    color: ${GlobalColors.SupportColors.blackMedium};
  }

  @media ${device.laptop} {
    p {
      width: 402px;
      height: 270px;
      text-align: start;
      margin: 0px;
      ${GlobalFontStyle.H3.regular};
    }
  }
`;

export const NotificationContainer = styled.div`
  @media ${device.laptop} {
    width: 50%;
    margin-bottom: 0px;
    margin-top: 210px;
    margin-left: 90px;

    .card:first-child {
      margin-bottom: 43px;
    }

    .card:last-child {
      margin-top: 50px;
    }
  }
`;

export const Card = styled.div`
  @media ${device.laptop} {
    display: flex;
    justify-content: ${(props) => props.type};

    .card-content {
      width: 360px;
      z-index: 1;
    }
  }
`;
