import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors } from '../../../Global/Global_style';

export const Card = styled.div`
  background-color: ${GlobalColors.SupportColors.pureWhite};
  padding: 14px 18px;
  margin-left: 24px;
  margin-right: 18px;
  margin-bottom: 16px;
  z-index: 8;
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  position: relative;

  span {
    margin-left: 6px;
    color: ${GlobalColors.SupportColors.blackLight};
    ${GlobalFontStyle.R.regular};
  }
`;

export const NotificationIcon = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: -50px;
`;

export const IconGoplan = styled.img`
  height: 14px;
  width: 50px;
`;

export const Shape = styled.img`
  position: absolute;
  right: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 97%;
`;

export const Title = styled.span`
  ${GlobalFontStyle.R2.regular};
  color: ${GlobalColors.SupportColors.black};
  margin-bottom: 4px;
  max-width: 80%;
  word-break: break-word;
`;

export const Description = styled.span`
  ${GlobalFontStyle.R1.regular};
  color: ${GlobalColors.SupportColors.grayLight};
  max-width: 90%;
  word-break: break-word;
`;
