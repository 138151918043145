import React from 'react';
import { useMediaQuery } from '@mui/material';

import AdvantagesPhoneMobile from '../../assets/Advantages/advantages-phone-mobile.png';
import AdvantagesPhoneWeb from '../../assets/Advantages/advantages-phone-web.png';
import AnimatedLogo from '../../assets/Advantages/animated-logo.gif';
import Carousel from './Carousel';
import {
  PageContainer,
  Title,
  Text,
  MobileContent,
  Card,
  Number,
  TextArea,
  CardTitle,
  CardText,
  DesktopContent,
} from './Advantages_style';

const Advantages = () => {
  const md = useMediaQuery('(min-width:1024px)');
  // First element must be the 8th, because if it is the 1st, the animation will broke
  const items = [
    {
      id: 8,
      title: 'Colaborativo',
      number: '08',
      text: 'Agora ficou fácil gerenciar sua propriedade, com todos os seus administradores conectados em um único lugar',
    },
    {
      id: 1,
      title: 'Sem burocracia',
      number: '01',
      text: 'Não é necessário baixar através de uma loja de aplicativos, basta acessar o link',
    },
    {
      id: 2,
      title: 'Otimização',
      number: '02',
      text: 'Ocupa pouco espaço de armazenamento do dispositivo',
    },
    {
      id: 3,
      title: 'Autonomia',
      number: '03',
      text: 'Receba atualizações de software de forma automática, sem qualquer incômodo',
    },
    {
      id: 4,
      title: 'Conectividade',
      number: '04',
      text: 'Aprimorado para trabalhar em redes de internet de baixa qualidade',
    },
    {
      id: 5,
      title: 'Seguro',
      number: '05',
      text: 'Construído para evitar que o conteúdo seja adulterado e manter todos os dados seguros',
    },
    {
      id: 6,
      title: 'Adaptável',
      number: '06',
      text: 'Se adequa a diferentes dispositivos: desktop, smartphone, tablet, qualquer formato de tela',
    },
    {
      id: 7,
      title: 'Intuitivo e personalizado',
      number: '07',
      text: 'Fácil de usar, foi pensado para agilizar a sua vida tornando suas tarefas mais simples',
    },
  ];

  return (
    <PageContainer id="advantages">
      <Title>Vantagens de usar nosso App</Title>

      <Text>
        Além de todos os beneficios que você pode ter acesso da palma da sua
        mão, o App Goplan tem inúmeras vantagens quando comparado aos demais
        aplicativos
      </Text>

      {md ? (
        <DesktopContent>
          <img src={AdvantagesPhoneWeb} alt="Phone" />
          <img
            src={AnimatedLogo}
            alt={'animated-logo'}
            width="260px"
            height="250px"
            style={{ position: 'absolute', left: '105px' }}
          />
          {items.map((item) => (
            <Card key={item.id} id={`item-${item.id}`}>
              <Number>{item.number}</Number>
              <TextArea>
                <CardTitle>{item.title}</CardTitle>
                <CardText>{item.text}</CardText>
              </TextArea>
            </Card>
          ))}
        </DesktopContent>
      ) : (
        <MobileContent>
          <img src={AdvantagesPhoneMobile} alt="Phone" />
          <img
            src={AnimatedLogo}
            alt={'animated-logo'}
            width="260px"
            height="250px"
            style={{
              position: 'absolute',
              top: '250px',
            }}
          />
          <Carousel items={items} active={1} />
        </MobileContent>
      )}
    </PageContainer>
  );
};

export default Advantages;
