import {
  PageContainer,
  NewAbout,
  ImgIcon,
  Header,
  ContentContainer,
  FirstPartTitle,
  SecondPartTitle,
  Description,
  NotificationContainer,
  Card,
} from './Notifications_style';

import NotificationCard from './Components/NotificationCard/NotificationCard';
import icon from '../../assets/Notifications/icon-notification.svg';
import bannerMobile from '../../assets/Notifications/Banner-mobile.png';
import bannerDesktop from '../../assets/Notifications/Banner-desktop.png';

const notificationItems = [
  {
    title: 'Previsão de hoje para Campinas, SP.',
    description: '⬆️️ 35°C | ⬇️ 23ºC | ☁️ 5% 0mm',
    type: 'flex-start',
  },
  {
    title: 'Quer plantar café? Confira dicas...',
    description:
      'O cafeeiro é pertecente ao gênero Coffea, cujas espécies mais conhecidas são a...',
    type: 'center',
  },
  {
    title: 'Cotação do dólar',
    description: 'R$ 5,45 | +0,5% | PTax R$ 5,34',
    type: 'flex-end',
  },
];

const Notifications = () => {
  return (
    <PageContainer>
      <NewAbout>
        <span>Acompanhe as novidades do app Goplan</span>
        <img
          src={bannerMobile}
          className="bannerMobile"
          alt="Telas do aplicativo"
        />
        <img
          src={bannerDesktop}
          className="bannerDesktop"
          alt="Telas do aplicativo"
        />

        <p>
          Fique de olho aqui no site para não perder nenhuma atualização da
          plataforma e ter acesso ao que há de mais recente no aplicativo da
          Goplan.
        </p>
        <button
          type="botton"
          onClick={() => window.open(process.env.REACT_APP_HOME, '_blank')}
        >
          Ir para o aplicativo
        </button>
      </NewAbout>

      <ImgIcon src={icon} alt="ícone notificação" />
      <Header id="notifications">
        <FirstPartTitle>
          <span>NOTIFI</span>
        </FirstPartTitle>
        <SecondPartTitle>
          <span>CAÇÕES</span>
        </SecondPartTitle>
      </Header>
      <ContentContainer>
        <Description>
          <p className="desc-mobile" style={{ textAlign: 'justify' }}>
            Com o Aplicativo da Goplan você consegue acompanhar desde a previsão
            do tempo para a sua cidade até dicas exclusivas enviadas pelo seu
            Franqueado! Tudo feito de forma personalizada, pensando na
            experiência individual de cada cliente. Tenha em mãos o melhor
            aplicativo do agronegócio!
          </p>
        </Description>
        <Description>
          <p className="desc-web" style={{ textAlign: 'justify' }}>
            Com o Aplicativo da Goplan você consegue acompanhar desde a previsão
            do tempo para a sua cidade até dicas exclusivas enviadas pelo seu
            Franqueado! Tudo feito de forma personalizada, pensando na
            experiência individual de cada cliente. Tenha em mãos o melhor
            aplicativo do agronegócio!
          </p>
        </Description>
        <NotificationContainer>
          {notificationItems.map((item) => (
            <Card type={item.type} key={item.title} className="card">
              <NotificationCard
                title={item.title}
                description={item.description}
                key={item.title}
              />
            </Card>
          ))}
        </NotificationContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default Notifications;
