import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

const iconAreaStyledBorders = (type) => {
  if ([1, 3, 4].includes(type)) {
    return `
      @media (max-width: 1023px) {
        border: 1px solid ${GlobalColors.Primary.verdePrimario};
        border-bottom: none;
        border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
      }
      border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
      background-position: right top, 4px 0;
    `;
  }
  if ([2, 5].includes(type)) {
    return `
      @media (max-width: 1023px) {
        border: 1px solid ${GlobalColors.Primary.verdePrimario};
        border-bottom: none;
        border-left: 2px solid  ${GlobalColors.Primary.verdePrimario}; 
      }
      border-left: 2px solid  ${GlobalColors.Primary.verdePrimario};      
      background-position: left top, 4px 0;
    `;
  }
};

const textAreaStyledBorders = (type) => {
  if ([1, 3].includes(type)) {
    return `
      @media (max-width: 1023px) {
        border: 1px solid ${GlobalColors.Primary.verdePrimario};
        border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
        border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
        border-left: 2px solid  ${GlobalColors.Primary.verdePrimario};
      }
      border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
      border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
      border-left: 2px solid  ${GlobalColors.Primary.verdePrimario};
      background-position: left top, 4px 0;
    `;
  }
  if ([2, 5].includes(type)) {
    return `
      @media (max-width: 1023px) {
        border: 1px solid ${GlobalColors.Primary.verdePrimario};
        border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
        border-left: 2px solid  ${GlobalColors.Primary.verdePrimario};
      }
      border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
      border-left: 2px solid  ${GlobalColors.Primary.verdePrimario};
      background-position: left top, 4px 0;
    `;
  }
  if ([4].includes(type)) {
    return `
      @media (max-width: 1023px) {
        border: 1px solid ${GlobalColors.Primary.verdePrimario};
        border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
        border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
      }
      border-bottom: 2px solid  ${GlobalColors.Primary.verdePrimario};
      border-right: 2px solid  ${GlobalColors.Primary.verdePrimario};
      background-position: right top, 4px 0;
    `;
  }
};

export const PageContainer = styled.div`
  margin-bottom: 41px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 28px;
  height: 22px;
  @media ${device.laptop} {
    height: 54px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FirstPartTitle = styled.div`
  background-color: ${GlobalColors.Primary.azulPrimario};
  color: ${GlobalColors.SupportColors.pureWhite};
  width: 40%;
  display: flex;
  justify-content: flex-end;
  ${GlobalFontStyle.H3.semibold};
  align-items: center;

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
  }
`;

export const SecondPartTitle = styled.div`
  color: ${GlobalColors.Primary.verdePrimario};
  width: 7%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1px;
  border-bottom: 0.5px solid;
  ${GlobalFontStyle.H3.semibold};

  @media ${device.laptop} {
    width: 6%;
    ${GlobalFontStyle.T2.semibold};
    border-bottom: 3px solid;
  }
`;

export const Description = styled.p`
  ${GlobalFontStyle.H6.regular};
  text-align: center;
  margin: 48px 32px 40px 26px;
  color: ${GlobalColors.SupportColors.blackMedium};

  @media ${device.laptop} {
    text-align: start;
    margin-left: 154px;
    ${GlobalFontStyle.H3.regular};
    margin-bottom: 118px;
  }
`;

export const Container = styled.div`
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    position: relative;

    :after {
      content: '';
      height: 326px;
      background-color: ${GlobalColors.Primary.azulPrimario};
      border-top: 8px solid ${GlobalColors.Primary.verdePrimario};
      position: absolute;
      width: 100%;
      bottom: 0px;
      z-index: -1;
    }
  }
`;

export const Topics = styled.h3`
  display: flex;
  justify-content: center;
  margin-bottom: 10.07px;
  color: ${GlobalColors.SupportColors.azulPrimario};
  ${GlobalFontStyle.H2.semibold};

  @media ${device.laptop} {
    ${GlobalFontStyle.T5.semibold};
  }
`;

export const TopicImg = styled.div`
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    img {
      width: 80px;
      height: 80px;
    }
  }
`;

export const TopicDescriptions = styled.p`
  display: flex;
  text-align: center;
  margin: 24.34px 8.78px 33.57px 8px;
  color: ${GlobalColors.SupportColors.blackMedium};
  ${GlobalFontStyle.H5.regular};

  @media ${device.laptop} {
    ${(props) =>
      props.type === 'dolar' ? `margin-bottom: 18px` : `margin-bottom: 44px`};
    ${GlobalFontStyle.H3.regular};
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 42px;
  }
`;

export const FirstTopicCommodities = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 194px;
  border-left: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  border-top: 3.35695px solid ${GlobalColors.Primary.verdePrimario};
  border-bottom: 3.35695px solid ${GlobalColors.Primary.verdePrimario};
  width: 112.46px;
  height: 135.96px;
  align-items: center;

  @media ${device.laptop} {
    width: 100%;
    height: 100%;
    margin-right: 196px;
    border-left: 2px solid ${GlobalColors.Primary.verdePrimario};
    border-top: 4px solid ${GlobalColors.Primary.verdePrimario};
    border-bottom: 4px solid ${GlobalColors.Primary.verdePrimario};
  }
`;

export const TopicImgCommodities = styled.img`
  margin-left: 200px;
  margin-top: 32.73px;
  margin-bottom: 36.09px;

  @media ${device.laptop} {
    width: 80px;
    height: 80px;
    margin-top: 39px;
    margin-bottom: 43px;
  }
`;

export const SecondTopicCommodities = styled.div`
  margin-bottom: 30px;
  border-left: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  border-bottom: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  width: 307.16px;
  display: flex;

  @media ${device.laptop} {
    margin-bottom: 0px;
    border-left: 2px solid ${GlobalColors.Primary.verdePrimario};
    border-bottom: 2px solid ${GlobalColors.Primary.verdePrimario};
    width: 100%;
  }
`;

export const FirstTopicDollar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -2px;
  border-right: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  height: 139.31px;
  width: 307.16px;

  @media ${device.laptop} {
    margin-right: -2px;
    border-right: 2px solid ${GlobalColors.Primary.verdePrimario};
    width: 100%;
    height: 100%;
  }
`;

export const SecondTopicDollar = styled.div`
  margin-bottom: 30px;
  border-left: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  border-bottom: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  border-right: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  width: 307.16px;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    margin-bottom: 0px;
    border-left: 2px solid ${GlobalColors.Primary.verdePrimario};
    border-bottom: 2px solid ${GlobalColors.Primary.verdePrimario};
    border-right: 2px solid ${GlobalColors.Primary.verdePrimario};
    width: 100%;
  }
`;

export const FirstTopicFavorite = styled.div`
  display: flex;
  justify-content: end;
  border-right: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  height: 139.31px;
  width: 307.16px;

  @media ${device.laptop} {
    border-right: 2px solid ${GlobalColors.Primary.verdePrimario};
    height: 100%;
    width: 100%;
  }
`;

export const SecondTopicFavorite = styled.div`
  margin-bottom: 15px;
  border-bottom: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  border-right: 1.67847px solid ${GlobalColors.Primary.verdePrimario};
  width: 307.16px;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 100%;
    margin-bottom: 0px;
    border-right: 2px solid ${GlobalColors.Primary.verdePrimario};
    border-bottom: 2px solid ${GlobalColors.Primary.verdePrimario};
  }
`;

export const TopicDescriptionsFavorite = styled.p`
  display: flex;
  margin: 24.34px 8.78px 33.57px 8px;
  ${GlobalFontStyle.H5.regular};
  color: ${GlobalColors.SupportColors.blackMedium};
  text-align: center;

  @media ${device.laptop} {
    margin: 42px 33px 45px 33px;
    ${GlobalFontStyle.H3.regular};
  }
`;

export const BorderEnd = styled.div`
  justify-content: end;
  display: flex;

  div:first-child {
    border-top: 3.3587px solid ${GlobalColors.Primary.verdePrimario};
    width: 112.15px;
  }
`;

export const BorderStart = styled.div`
  div:first-child {
    border-top: 3.3587px solid ${GlobalColors.Primary.verdePrimario};
    width: 112.15px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: all 1s ease;
`;

export const CardTitle = styled.div`
  ${GlobalFontStyle.H2.semibold}
  text-align: center;
  color: ${GlobalColors.Primary.azulPrimario};
  width: 100%;

  @media ${device.tablet} {
    ${GlobalFontStyle.H2.semibold}
  }
  @media ${device.laptop} {
    ${GlobalFontStyle.T5.semibold}
  }
  @media ${device.laptopL} {
    ${GlobalFontStyle.T5.semibold}
  }
  @media ${device.desktop} {
    ${GlobalFontStyle.T5.semibold}
  }
  @media ${device.desktopL} {
    ${GlobalFontStyle.T5.semibold}
  }
`;

export const IconsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 166px;
  background-image: linear-gradient(
      ${GlobalColors.Primary.verdePrimario},
      ${GlobalColors.Primary.verdePrimario}
    ),
    linear-gradient(
      ${GlobalColors.SupportColors.pureWhite},
      ${GlobalColors.SupportColors.pureWhite}
    );
  background-repeat: no-repeat;
  background-size: 40% 4px, 100% calc(100% - 4px);
  ${({ borderType }) => iconAreaStyledBorders(borderType)}
`;

export const Icon = styled.img`
  height: 80px;
  width: 80px;
  color: ${GlobalColors.SupportColors.black};
`;

export const TextArea = styled.div`
  ${GlobalFontStyle.H5.regular}
  padding: 24px;
  height: 219px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: ${GlobalColors.SupportColors.blackMedium};

  background-image: linear-gradient(
      ${GlobalColors.Primary.verdePrimario},
      ${GlobalColors.Primary.verdePrimario}
    ),
    linear-gradient(
      ${GlobalColors.SupportColors.pureWhite},
      ${GlobalColors.SupportColors.pureWhite}
    );
  background-repeat: no-repeat;
  background-size: 40% 4px, 100% calc(100% - 4px);

  ${({ borderType }) => textAreaStyledBorders(borderType)}
  @media ${device.tablet} {
    ${GlobalFontStyle.H5.regular}
  }
  @media ${device.laptop} {
    ${GlobalFontStyle.H3.regular}
  }
  @media ${device.laptopL} {
    ${GlobalFontStyle.H3.regular}
  }
  @media ${device.desktop} {
    ${GlobalFontStyle.H3.regular}
  }
`;
