import styled from 'styled-components';
const GlobalFontFamily = 'Montserrat, sans-serif';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const GlobalFontStyle = {
  H8: {
    regular: {
      font: `400 9px/8px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    semibold: {
      font: `550 9px/8px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H7: {
    regular: {
      font: `400 11px/13px ${GlobalFontFamily}`,
      letterSpacing: 'auto',
    },
  },
  H6: {
    regular: {
      font: `400 12px/18px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    medium: {
      font: `500 12px/27px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    semibold: {
      font: `550 12px/14px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H5: {
    regular: {
      font: `400 14px/21px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    regularUnderline: {
      font: `400 14px/21px ${GlobalFontFamily}`,
      letterSpacing: '0px',
      textDecorationLine: 'underline',
    },
    semibold: {
      font: `550 14px/17px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    bold: {
      font: `bold 14px/21px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H4: {
    regular: {
      font: `400 16px/18px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    semibold: {
      font: `550 16px/21px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H3: {
    medium: {
      font: `500 18px/27px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    regular: {
      font: `400 18px/27px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
    semibold: {
      font: `550 18px/27px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H2: {
    semibold: {
      font: `550 20px/24px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H1: {
    semibold: {
      font: `550 30px/30px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  H0: {
    semibold: {
      font: `550 70px/85px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  T1: {
    semibold: {
      font: `550 79px/95px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  T2: {
    semibold: {
      font: `550 50px/95px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  T3: {
    semibold: {
      font: `550 38px/54px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  T4: {
    semibold: {
      font: `550 27px/33px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  T5: {
    semibold: {
      font: `550 24px/36px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  R: {
    regular: {
      font: `400 12px/16px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  R1: {
    regular: {
      font: `400 13px/20px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
  R2: {
    regular: {
      font: `400 15px/20px ${GlobalFontFamily}`,
      letterSpacing: '0px',
    },
  },
};

export const GlobalColors = {
  Primary: {
    azulPrimario: ' #011B3A',
    verdePrimario: '#5BB222',
    yellow: '#EFAA41',
  },

  SupportColors: {
    pureBlack: '#000000',
    azulPrimario: ' #0C223D',
    azulSecundario: ' #011b3a',
    verdeSecundario: '#E6F4B4',
    skyBlue: '#3497f9',
    greenLight: '#c5d58c',
    blackLight: '#707070',
    black: '#222222',
    blackMedium: '#353535',
    grayLight: '#686868',
    shadowGray: '#E4E4E4',
    grayMedium: '#94a2b3',
    whiteLight: '#F6F6F6',
    pureWhite: '#FFFFFF',
    pureGray: '#EDEDED',
    error: '#FF5652',
  },
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '1920px',
  desktopL: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};
