import styled from 'styled-components';
import { GlobalColors, device, GlobalFontStyle } from '../Global/Global_style';

export const PageContainer = styled.div`
  border-bottom: 0.5px solid ${GlobalColors.SupportColors.shadowGray};

  ${(props) => !props.isOpen} {
    border-bottom: none;
    background-color: ${GlobalColors.SupportColors.azulPrimario};
  }

  @media ${device.laptop} {
    border-bottom: none;
  }
`;

export const HeaderWeb = styled.div`
  @media ${device.laptop} {
    background-color: ${GlobalColors.SupportColors.verdeSecundario};
    list-style: none;
    display: flex;
    padding: 17px 0px 16px 0px;
    ${GlobalFontStyle.H3.semibold};
    color: ${GlobalColors.SupportColors.azulPrimario};
  }
  display: none;

  li {
    margin-right: 92px;
    cursor: pointer;
  }

  li:first-child {
    margin-left: 156px;
  }

  li:last-child {
    margin-right: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.img`
  margin-bottom: 16px;
  margin-top: 17px;
  margin-left: 32px;
  width: 146px;

  @media ${device.laptop} {
    margin-left: 156px;
    margin-bottom: 29px;
    margin-top: 40px;
    width: 241px;
  }
`;

export const ButtonMenu = styled.button`
  background-color: ${GlobalColors.SupportColors.pureWhite};
  border: none;
  margin-bottom: 21px;
  margin-top: 18px;
  padding: 0px;
  margin-right: 31px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  position: relative;

  ${(props) => !props.isOpen} {
    background-color: ${GlobalColors.SupportColors.azulPrimario};
  }

  @media ${device.laptop} {
    visibility: hidden;
  }
`;

export const DropdownMenu = styled.ul`
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: ${GlobalColors.SupportColors.azulPrimario};
  position: absolute;
  top: 0;
  z-index: 1;
  ${(props) => !props.isOpen} {
    max-height: 488px;
  }

  li {
    margin-top: 17px;
    list-style: none;

    ${(props) => !props.isOpen} {
      margin-top: 14px;
    }
  }

  span {
    display: flex;
    padding: 10px;
    text-align: start;
    text-decoration: none;
    background: ${GlobalColors.SupportColors.azulPrimario};
    color: ${GlobalColors.SupportColors.pureWhite};
    transition: 0.5s;
    ${GlobalFontStyle.H3.semibold};
    margin-left: 31px;
  }
`;

export const CardLogIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
  margin-bottom: 25px;

  .bnt-login {
    background-color: ${GlobalColors.SupportColors.pureWhite};
    color: ${GlobalColors.Primary.verdePrimario};
  }

  span {
    margin-left: 0px;
  }
`;

export const ButtonLogIn = styled.button`
  border-radius: 23.5px;
  border: none;
  padding: 10px 82px;
  ${GlobalFontStyle.H3.semibold};
  width: 40%;
  display: flex;
  justify-content: center;
`;
