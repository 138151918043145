import {
  Card,
  NotificationHeader,
  NotificationIcon,
  IconGoplan,
  Shape,
  Content,
  Title,
  Description,
} from './NotificationCard_style';

import NotificationIconSVG from '../../../../assets/Notifications/notification-icon.svg';
import NotificationBadge from '../../../../assets/Notifications/notification-badge.svg';
import shape from '../../../../assets/Notifications/shape.svg';

const NotificationCard = ({ title, description }) => {
  return (
    <Card className="card-content">
      <NotificationHeader>
        <IconGoplan src={NotificationBadge} alt="ícone Goplan" />
        <span>app.goplan.com.br | 10:36</span>
        <Shape src={shape} alt="" />
        <NotificationIcon src={NotificationIconSVG} alt="" />
      </NotificationHeader>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
    </Card>
  );
};

export default NotificationCard;
