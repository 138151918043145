import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const PageContainer = styled.div`
  margin-bottom: 41px;
  /* TODO: Remove this line when tutorial video is ready */
  display: none;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 28px;
  height: 22px;
  @media ${device.laptop} {
    height: 54px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FirstPartTitle = styled.div`
  background-color: ${GlobalColors.Primary.azulPrimario};
  color: ${GlobalColors.SupportColors.pureWhite};
  width: 48%;
  display: flex;
  justify-content: flex-end;
  ${GlobalFontStyle.H3.semibold};
  align-items: center;

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
  }
`;

export const SecondPartTitle = styled.div`
  color: ${GlobalColors.Primary.verdePrimario};
  width: 7%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1px;
  border-bottom: 0.5px solid;
  ${GlobalFontStyle.H3.semibold};

  @media ${device.laptop} {
    width: 6%;
    ${GlobalFontStyle.T2.semibold};
    border-bottom: 3px solid;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
  }

  button {
    color: ${GlobalColors.SupportColors.pureWhite};
    background-color: ${GlobalColors.Primary.verdePrimario};
    ${GlobalFontStyle.H5.semibold}
    height: 39px;
    width: 171px;
    border: none;
    cursor: pointer;
    margin: 0px auto 0px auto;

    @media ${device.laptop} {
      z-index: 1;
      ${GlobalFontStyle.H3.semibold}
      grid-column:1;
      grid-row: 4;
      height: 50px;
      width: 240px;
      margin-left: 20%;
    }
  }
`;

export const TextInfortmation = styled.p`
  ${GlobalFontStyle.H6.regular};
  color: ${GlobalColors.SupportColors.blackMedium};
  margin: 0px 41px 0px 42px;
  text-align: center;

  @media ${device.laptop} {
    grid-row: 1;
    grid-column: 1;
    ${GlobalFontStyle.H3.regular};
    color: ${GlobalColors.SupportColors.azulPrimario};
    text-align: start;
    margin: 29px 20% 0px 20%;
  }
`;

export const TextSteps = styled.p`
  ${GlobalFontStyle.H6.regular};
  color: ${GlobalColors.SupportColors.blackMedium};
  margin: 0px 41px 0px 42px;
  text-align: center;

  @media ${device.laptop} {
    grid-row: 2;
    grid-column: 1;
    ${GlobalFontStyle.H3.regular};
    color: ${GlobalColors.SupportColors.azulPrimario};
    text-align: start;
    margin: 0px 20%;
  }
`;

export const TopicsItens = styled.div`
  img {
    margin-right: 6px;
  }

  p:nth-child(1) {
    margin-top: 25px;
    @media ${device.laptop} {
      margin-top: 10px;
    }
  }

  p:last-child {
    margin-bottom: 25px;
    @media ${device.laptop} {
      margin-bottom: 10px;
    }
  }

  p {
    text-align: center;
    color: ${GlobalColors.SupportColors.blackMedium};
    ${GlobalFontStyle.H6.regular};
    margin: 16px auto 0px auto;

    @media ${device.laptop} {
      text-align: start;
      ${GlobalFontStyle.H3.regular};
      color: ${GlobalColors.SupportColors.azulPrimario};
    }
  }

  @media ${device.laptop} {
    grid-row: 3;
    grid-column: 1;
    ${GlobalFontStyle.H3.regular};
    color: ${GlobalColors.SupportColors.azulPrimario};
    margin-left: 20%;
  }
`;

export const VideoTuTorial = styled.div`
  width: 307px;
  height: 205px;
  margin: 25px auto 24px auto;

  /* TODO: Remove this part when tutorial video is done */
  img:nth-child(2) {
    display: none;
  }

  @media ${device.laptop} {
    margin-right: 100px;
    img:nth-child(1) {
      display: none;
    }

    img:nth-child(2) {
      display: grid;
    }

    grid-row: span 4;
    grid-column: span 1;
    width: 583px;
    height: 389px;
  }
`;
