import { useMediaQuery } from '@mui/material';
import {
  Container,
  MapBox,
  CountryImg,
  BrandsArea,
  Brand,
  Content,
  MapContent,
  Toggle,
} from './Exclusive_style.js';
import Buttom from '../Global/Buttom';

import BrazilImg from '../../assets/Exclusive/country.png';
import Agroata from '../../assets/Exclusive/agroata.png';
import AgroConfianca from '../../assets/Exclusive/agroconfianca.png';
import AgroFarm from '../../assets/Exclusive/agrofarm.png';
import AgroFertil from '../../assets/Exclusive/agrofertil.png';
import AgroTech from '../../assets/Exclusive/agrotech.png';
import ApoioAgricola from '../../assets/Exclusive/apoioagricola.png';
import CasaDoCafe from '../../assets/Exclusive/casadocafe.png';
import CasaFertil from '../../assets/Exclusive/casafertil.png';
import CercaViva from '../../assets/Exclusive/cercaviva.png';
import Duquima from '../../assets/Exclusive/duquima.png';
import Fitovet from '../../assets/Exclusive/fitovet.png';
import Regional from '../../assets/Exclusive/regionalA.png';
import Plantar from '../../assets/Exclusive/plantar.png';
import Polo from '../../assets/Exclusive/polo.png';
import Soberana from '../../assets/Exclusive/soberana.png';
import Tche from '../../assets/Exclusive/tche.png';
import Toagro from '../../assets/Exclusive/toagro.png';
import Tomatec from '../../assets/Exclusive/tomatec.png';

const Exclusive = () => {
  const md = useMediaQuery('(min-width:1024px)');

  return (
    <Container>
      <Toggle>EXCLUSIVO</Toggle>
      <MapBox>
        <CountryImg src={BrazilImg} />
        <Content>
          <MapContent>
            Seja cliente de um Franqueado Goplan para ter acesso a essa e outras
            ferramentas exclusivas. Valorize o seu negócio, faça parte da mais
            ampla e sólida rede de franquias do Brasil!
          </MapContent>
          {md ? (
            <BrandsArea>
              <Brand src={AgroTech} alt="AgroTech" />
              <Brand src={Agroata} alt="Agroata" />
              <Brand src={AgroConfianca} alt="AgroConfianca" />
              <Brand src={AgroFarm} alt="AgroFarm" />
              <Brand src={AgroFertil} alt="AgroFertil" />
              <Brand src={ApoioAgricola} alt="ApoioAgricola" />
              <Brand src={CasaDoCafe} alt="CasaDoCafe" />
              <Brand src={CasaFertil} alt="CasaFertil" />
              <Brand src={CercaViva} alt="CercaViva" />
              <Brand src={Duquima} alt="Duquima" />
              <Brand src={Fitovet} alt="Fitovet" />
              <Brand src={Plantar} alt="Plantar" />
              <Brand src={Polo} alt="Polo" />
              <Brand src={Regional} alt="RegionalAgro" />
              <Brand src={Soberana} alt="Soberana" />
              <Brand src={Tche} alt="Tche" />
              <Brand src={Toagro} alt="Toagro" />
              <Brand src={Tomatec} alt="Tomatec" />
            </BrandsArea>
          ) : (
            <Buttom
              content="Conhecer"
              type={'yellow'}
              mobileMargin={'0px 0px 58px 0px'}
              link="https://goplan.com.br/#parceiros"
            />
          )}
        </Content>
      </MapBox>
    </Container>
  );
};

export default Exclusive;
