import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';
import Iphone from '../../assets/OurApp/iphone.png';
import IphoneDesktop from '../../assets/OurApp/Iphone-desktop.png';
import handsWithDirt from '../../assets/OurApp/Hands-with-dirt.png';

export const Container = styled.div`
  background: linear-gradient(
    to bottom,
    ${GlobalColors.SupportColors.whiteLight} 0%,
    ${GlobalColors.SupportColors.whiteLight} 75%,
    ${GlobalColors.SupportColors.pureWhite} 40%,
    ${GlobalColors.SupportColors.pureWhite} 100%
  );
  margin-bottom: 35px;
  margin-top: 30px;
  @media ${device.laptop} {
    background: ${GlobalColors.SupportColors.whiteLight};
    height: 564px;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  margin-bottom: 16px;
  margin: 20px 0px 16px 0px;
  height: 22px;
  justify-content: center;
  @media ${device.laptop} {
    height: 54px;
  }
`;

export const FistPart = styled.div`
  width: 8%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${GlobalColors.Primary.verdePrimario};
  margin: 0px 1px 0px 42%;

  @media ${device.laptop} {
    width: 6%;
    border-bottom: 3px solid ${GlobalColors.Primary.verdePrimario};
  }
`;

export const Title = styled.span`
  ${GlobalFontStyle.H3.semibold};
  color: ${GlobalColors.Primary.verdePrimario};

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
    text-align: right;
  }
`;

export const SecondPart = styled.div`
  width: 50%;
  background-color: ${GlobalColors.Primary.azulPrimario};
  display: flex;
  align-items: center;
  margin: 0px;
  @media ${device.laptop} {
    z-index: 2;
    width: 52%;
  }
`;

export const TitleInColor = styled.span`
  ${GlobalFontStyle.H3.semibold};
  color: ${GlobalColors.SupportColors.pureWhite};
  padding: 0px 0px 0px 5px;

  @media ${device.laptop} {
    ${GlobalFontStyle.T2.semibold};
    text-align: left;
  }
`;

export const PageContent = styled.div`
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 3fr 1fr auto;
    grid-template-rows: auto auto auto;
    position: relative;
  }
`;

export const SubTitle = styled.span`
  margin: 0px 0px 10px 0px;
  ${GlobalFontStyle.H1.semibold};

  color: ${GlobalColors.SupportColors.azulPrimario};
  display: flex;
  justify-content: center;
  text-align: center;

  @media ${device.laptop} {
    grid-row: 1;
    grid-column: 1;
    width: 577px;
    grid-template-rows: max-content;
    margin-top: 36px;
    margin: 36px 8px 18px 48px;
    font: 600 65px/95px Montserrat, sans-serif;
    text-align: left;
    justify-content: start;
  }
  @media ${device.laptopL} {
    grid-row: 1;
    grid-column: 1;
    grid-template-rows: max-content;
    ${GlobalFontStyle.T1.semibold};
    width: 600px;
    margin-top: 36px;
    margin: 36px 0px 34px 154px;
    text-align: left;
  }
`;

export const Text = styled.span`
  ${GlobalFontStyle.H6.regular};
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 13px 18px 0px 25px;
  color: ${GlobalColors.SupportColors.blackMedium};

  @media ${device.laptop} {
    grid-row: 2;
    grid-column: 1;
    align-items: center;
    ${GlobalFontStyle.H3.regular};
    text-align: left;
    margin: 0px 262px 45px 48px;
  }

  @media ${device.laptopL} {
    grid-row: 2;
    grid-column: 1;
    align-items: center;
    width: 585px;
    ${GlobalFontStyle.H3.regular};
    text-align: left;
    margin: 0px 262px 34px 154px;
  }
`;

export const CellphoneImg = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1440px) {
    div:nth-child(1) {
      width: 233px;
      height: 416px;
      background: url(${Iphone});
    }
  }

  @media ${device.laptop} {
    grid-row: span 4;
    grid-column: span 2;
    div:nth-child(1) {
      position: absolute;
      z-index: 2;
      width: 280px;
      height: 500px;
      top: -69px;
      bottom: -20px;
      background: url(${IphoneDesktop});
      right: 135px;
    }
  }

  @media ${device.laptopL} {
    grid-row: span 3;
    grid-column: span 2;
    div:nth-child(1) {
      position: absolute;
      z-index: 2;
      width: 280px;
      height: 500px;
      top: -69px;
      bottom: -20px;
      background: url(${IphoneDesktop});
      right: 180px;
    }
  }
`;

export const DirtInHandsImg = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    grid-row: span 3;
    grid-column: span 2;
    div:nth-child(1) {
      position: absolute;
      z-index: 1;
      width: 363px;
      height: 601px;
      top: -98px;
      right: 0px;
      background: url(${handsWithDirt});
    }
  }
`;

export const BottomPage = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    grid-row: 3;
    grid-column: 1;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 0px 4px;
  }

  @media ${device.laptopL} {
    grid-row: 3;
    grid-column: 1;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 0px 110px;
  }
`;

export const JoinUsText = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (min-width: 768px) {
  }
`;

export const OrText = styled.span`
  ${GlobalFontStyle.H5.semibold};
  text-align: center;
  color: ${GlobalColors.SupportColors.grayMedium};
  margin: 0px 5px 0px 0px;
  @media (min-width: 768px) {
    ${GlobalFontStyle.H3.semibold};
  }
`;

export const JoinText = styled.span`
  ${GlobalFontStyle.H5.semibold};
  text-align: center;
  color: ${GlobalColors.SupportColors.skyBlue};
  @media (min-width: 768px) {
    ${GlobalFontStyle.H3.semibold};
  }
`;
