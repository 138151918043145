import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global_style';

export const ButtomAction = styled.button`
  border: none;
  background-color: ${(props) =>
    props.type === 'yellow'
      ? `${GlobalColors.Primary.yellow};`
      : `${GlobalColors.SupportColors.pureWhite};`};

  display: flex;
  justify-content: center;

  @media ${device.laptop} {
    background-color: ${(props) =>
      props.type === 'yellow'
        ? `${GlobalColors.Primary.yellow};`
        : props.type === 'green'
        ? `${GlobalColors.SupportColors.pureWhite};`
        : `${GlobalColors.SupportColors.whiteLight};`};
  }
`;

export const ButtomText = styled.span`
  padding: ${(props) => (props.type === 'yellow' ? `0px 15px` : `0px 25px`)};
  height: 50px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.type === 'yellow'
        ? `${GlobalColors.SupportColors.pureWhite}`
        : `${GlobalColors.Primary.verdePrimario}`};
  max-height: 50px;
  ${GlobalFontStyle.H5.semibold};
  width: ${(props) => (props.type === 'yellow' ? `180px` : 'auto')};

  color: ${(props) =>
    props.type === 'yellow'
      ? `${GlobalColors.SupportColors.pureWhite};`
      : `${GlobalColors.SupportColors.azulPrimario}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.mobileMargin};

  @media ${device.laptop} {
    ${GlobalFontStyle.H3.semibold};
    margin: ${(props) => props.desktopMargin};
    padding: ${(props) => props.textPadding};
    width: ${(props) =>
      props.type === 'yellow'
        ? `180px`
        : props.type === 'white'
        ? 'auto'
        : '310px'};
  } ;
`;
