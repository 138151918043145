import { ButtomText, ButtomAction } from './Buttom';

const Buttom = (props) => {
  return (
    <ButtomAction
      onClick={() =>
        window.open(props.link || process.env.REACT_APP_HOME, '_blank')
      }
      type={props.type}
    >
      <ButtomText
        type={props.type}
        buttomMargin={props.buttomMargin}
        textPadding={props.textPadding}
        mobileMargin={props.mobileMargin}
        desktopMargin={props.desktopMargin}
      >
        {props.content}
      </ButtomText>
    </ButtomAction>
  );
};

export default Buttom;
