import React from 'react';
import { useCookies } from 'react-cookie';
import {
  CookiesConsent,
  CookieText,
  CookiesButtom,
  CardAlert,
  CardAlertButton,
} from './Cookies_style';

const tenYeas = 315569520000;

const Cookies = () => {
  const [cookies, setCookies] = useCookies(['CONSENT']);

  function handleCookies() {
    setCookies('CONSENT', true, {
      path: '/',
      // Expires 10 years after click in ACEITAR button
      expires: new Date(new Date().getTime() + tenYeas),
    });
  }

  return (
    <>
      {!cookies.CONSENT && (
        <CookiesConsent id="cookie">
          <CardAlert>
            <CookieText>
              Bem vindo! Para podermos apresentar o nosso site da melhor forma
              possível, utilizamos cookies. Ao prosseguir navegando no site, o
              usuário declara que concorda com a utilização de cookies.
            </CookieText>
          </CardAlert>
          <CardAlertButton>
            <CookiesButtom onClick={() => handleCookies()}>
              ACEITAR
            </CookiesButtom>
          </CardAlertButton>
        </CookiesConsent>
      )}
    </>
  );
};

export default Cookies;
