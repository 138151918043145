// Based on https://codepen.io/onion2k/pen/xxZYBVj
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Number,
  TextArea,
  CardTitle,
  CardText,
} from './Advantages_style.js';
import './carousel.css';

const AdvantagesCarousel = ({ items, active }) => {
  const [checked, setChecked] = useState(active);
  const [itemsToShow, setItemsToShow] = useState(items);

  const changeItem = useCallback(() => {
    setItemsToShow((prevState) => {
      const newItems = [...prevState];
      const firstItem = newItems.shift();
      newItems.push(firstItem);
      return newItems;
    });
    setChecked((prevTime) => (prevTime === 8 ? 1 : prevTime + 1));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      changeItem();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [changeItem]);

  return (
    <section id="advantage">
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 1}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 2}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 3}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 4}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 5}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 6}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 7}
      />
      <input
        type="radio"
        name="position"
        onChange={() => {}}
        checked={checked === 8}
      />
      <main id="carousel-advantages">
        {itemsToShow.map((item) => (
          <Card key={item.id} className="item-advantage">
            <Number>{item.number}</Number>
            <TextArea>
              <CardTitle>{item.title}</CardTitle>
              <CardText>{item.text}</CardText>
            </TextArea>
          </Card>
        ))}
      </main>
    </section>
  );
};

export default AdvantagesCarousel;
