import Menu from '../../components/Menu';
import OurApp from '../../components/OurApp';
import Benefits from '../../components/Benefits';
import Notifications from '../../components/Notifications';
import Advantages from '../../components/Advantages';
import Exclusive from '../../components/Exclusive';
import Footer from '../../components/Footer';
import Cookies from '../../components/Cookies';
import { Container } from '../../components/Global/Global_style';
import AppAccess from '../../components/AppAccess';

const Home = () => {
  return (
    <Container>
      <Menu />
      <OurApp />
      <Benefits />
      <Notifications />
      <Advantages />
      <AppAccess />
      <Exclusive />
      <Footer />
      <Cookies />
    </Container>
  );
};

export default Home;
