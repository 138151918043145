import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const Container = styled.div`
  position: relative;
`;

export const FooterContentBox = styled.div`
  background-color: ${GlobalColors.SupportColors.azulPrimario};
  display: flex;
  padding: 55px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media ${device.laptop} {
    align-items: center;
    height: 407px;
    padding: 108px 0 0 0;
  }
`;

export const AuthBox = styled.div`
  width: 90%;
  margin: 0px 16px;
  text-align: center;
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const LogoImg = styled.img`
  width: 127px;
  @media ${device.laptop} {
    width: 185px;
  }
`;

export const AuthText = styled.p`
  margin-top: 29px;
  ${GlobalFontStyle.H6.semibold};
  color: ${GlobalColors.SupportColors.pureWhite};
`;

export const AuthLastText = styled.a`
  ${GlobalFontStyle.H6.medium};
  color: ${GlobalColors.SupportColors.pureWhite};
  background-color: ${GlobalColors.SupportColors.azulPrimario};
  text-decoration: none;

  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &:hover {
    color: ${GlobalColors.Primary.verdePrimario};
  }

  @media ${device.laptop} {
    ${GlobalFontStyle.H5.semibold};
    padding-top: 33px;
    display: flex;
  }
`;

export const SocialMediaIcons = styled.div`
  cursor: pointer;
  img {
    width: 20x;
    height: 20px;
    padding: 0px 18px 10px;
  }
`;

export const InstagramSvg = styled.img`
  width: 32px !important;
  height: 40px !important;
  padding: 0px !important;
`;

export const ConectUs = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

export const NavLinks = styled.div`
  hr {
    display: none;
  }

  @media ${device.laptop} {
    min-width: 614px;
    padding-left: 33px;

    hr {
      display: block;
      margin-top: 29px;
    }
  }
`;

export const AuthUl = styled.ul`
  ${GlobalFontStyle.H6.semibold};
  color: ${GlobalColors.SupportColors.pureWhite};
  cursor: pointer;
  list-style-type: none;
  padding: 0px;
  flex-wrap: wrap;
  text-align: center;
  @media ${device.laptop} {
    display: flex;
    place-content: center;
  }
`;

export const AuthLi = styled.li`
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 10px;

  :first-child {
    margin-top: 30px;
  }

  @media ${device.laptop} {
    width: auto;
    padding-right: 33px;
    :first-child {
      margin-top: 0px;
    }
  }
`;

export const AuthLink = styled.a`
  ${GlobalFontStyle.H6.semibold};
  color: ${GlobalColors.SupportColors.pureWhite};
  text-decoration: none;

  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &:hover {
    color: ${GlobalColors.Primary.verdePrimario};
  }
`;

export const DividerLinks = styled.div`
  height: 63px;
  background-color: ${GlobalColors.SupportColors.azulPrimario};
  border-bottom: 1px solid ${GlobalColors.SupportColors.pureWhite};
`;

export const Version = styled.div`
  position: absolute;
  bottom: 10px;
  color: white;
  left: 23px;
`;
