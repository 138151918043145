import React from 'react';

import ArrowCheck from '../../assets/AppAccess/Arrow-check.svg';
import TemporaryImg from '../../assets/AppAccess/temporary-img.png';
import TemporaryImgDesktop from '../../assets/AppAccess/temporary-img-desktop.png';
import {
  PageContainer,
  Header,
  FirstPartTitle,
  SecondPartTitle,
  TextInfortmation,
  TopicsItens,
  VideoTuTorial,
  Container,
  TextSteps,
} from './AppAccess_style';

const AppAccess = () => {
  return (
    <PageContainer>
      <Header>
        <FirstPartTitle>
          <span>ACESSE O</span>
        </FirstPartTitle>
        <SecondPartTitle>
          <span>APP</span>
        </SecondPartTitle>
      </Header>
      <Container>
        <TextInfortmation>
          Para aproveitar de todas as funcionalidades e ainda garantir as
          atualizações das versões futuras, acesse agora o aplicativo Goplan.
        </TextInfortmation>
        <TopicsItens>
          <p>
            <img src={ArrowCheck} alt="Sinal de checado" /> Sem burocracia
          </p>
          <p>
            <img src={ArrowCheck} alt="Sinal de checado" />
            Rápido, fácil e seguro
          </p>
          <p>
            <img src={ArrowCheck} alt="Sinal de checado" />
            Adaptável para qualquer formato de tela
          </p>
        </TopicsItens>
        <TextSteps>
          É fácil e rápido, basta acessar o link e seguir o passo a passo, como
          no video abaixo.
        </TextSteps>
        {/* TODO: Add video tutorial */}
        <VideoTuTorial>
          <img src={TemporaryImg} alt="Imagen temporaria" />
          <img src={TemporaryImgDesktop} alt="Imagen temporaria" />
        </VideoTuTorial>
        <button
          type="botton"
          onClick={() => window.open(process.env.REACT_APP_HOME, '_blank')}
        >
          Ir para o aplicativo
        </button>
      </Container>
    </PageContainer>
  );
};

export default AppAccess;
