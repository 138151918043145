import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  ${GlobalFontStyle.H2.semibold}
  color: ${GlobalColors.Primary.azulPrimario};

  @media ${device.laptop} {
    ${GlobalFontStyle.T3.semibold}
  }
`;

export const Text = styled.span`
  ${GlobalFontStyle.H6.regular}
  color: ${GlobalColors.SupportColors.blackMedium};
  text-align: center;
  padding: 33px;

  @media ${device.laptop} {
    ${GlobalFontStyle.H3.regular}
    max-width: 843px;
  }
`;

export const Card = styled.div`
  display: -webkit-inline-box;
  background-color: white;
  transition: all 1s ease;
  row-gap: 16px;

  @media ${device.laptop} {
    margin: 0 15%;
  }
`;

export const Number = styled.div`
  ${GlobalFontStyle.H3.semibold}
  color: ${GlobalColors.SupportColors.whiteLight};

  background-color: ${GlobalColors.Primary.verdePrimario};
  opacity: 0.85;
  width: 45px;
  height: 39px;
  border-radius: 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    ${GlobalFontStyle.T5.semibold}
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4%;
`;

export const CardTitle = styled.span`
  ${GlobalFontStyle.H3.semibold}
  color: ${GlobalColors.SupportColors.blackMedium};

  @media ${device.laptop} {
    ${GlobalFontStyle.T5.semibold}
  }
`;

export const CardText = styled.span`
  ${GlobalFontStyle.H5.regular}
  color: ${GlobalColors.SupportColors.blackMedium};

  @media ${device.laptop} {
    ${GlobalFontStyle.H3.regular}
    color: ${GlobalColors.SupportColors.black};
  }
`;

export const MobileContent = styled(PageContainer)`
  position: relative;
`;

export const DesktopContent = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: 1fr 453px 1fr;
  align-items: center;
  justify-items: center;
  position: relative;

  img {
    grid-row: 1 / 5;
    grid-column: 2 / 3;
  }

  #item-1 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  #item-2 {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  #item-3 {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  #item-4 {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }
  #item-5 {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }

  #item-6 {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
  }

  #item-7 {
    grid-row: 3 / 4;
    grid-column: 3 / 4;
  }

  #item-8 {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
  }
`;
