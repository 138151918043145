import styled from 'styled-components';
import { GlobalFontStyle, GlobalColors, device } from '../Global/Global_style';

export const CookiesConsent = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: ${GlobalColors.Primary.yellow};
  z-index: 120;
  padding: 16px 16px;
  justify-content: space-between;
`;

export const CookieText = styled.span`
  max-width: 240px;
  max-height: 144px;
  ${GlobalFontStyle.H4.regular};
  color: ${GlobalColors.SupportColors.pureWhite};

  @media ${device.tablet} {
    max-width: 600px;
    max-height: 54px;
    ${GlobalFontStyle.H4.regular};
    text-align: left;
  }

  @media ${device.laptop} {
    ${GlobalFontStyle.H3.regular};
    max-width: 900px;
    max-height: 54px;
    text-align: left;
  }
  @media ${device.laptopL} {
    ${GlobalFontStyle.H3.regular};
    max-width: 1000px;
    max-height: 54px;
    text-align: left;
  }
`;

export const CookiesButtom = styled.button`
  display: flex;
  color: ${GlobalColors.SupportColors.pureWhite};
  width: 73px;
  height: 33px;
  ${GlobalFontStyle.H5.semibold};
  align-items: center;
  border: 1px solid;
  justify-content: center;
  cursor: pointer;
  background-color: ${GlobalColors.Primary.yellow};

  @media ${device.laptop} {
    margin: 17px 0px 17px 0px;
  }
`;

export const CardAlert = styled.div`
  display: flex;
  margin-left: 19px;
  @media ${device.laptop} {
    align-items: center;
    margin-left: 39px;
  }
`;

export const CardAlertButton = styled.div`
  display: flex;
  margin-right: 20px;
  @media ${device.tablet} {
    margin-right: 40px;
  }
  @media ${device.laptop} {
    margin-right: 61px;
  }
`;
