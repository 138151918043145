import {
  Container,
  Text,
  Title,
  SubTitle,
  BottomPage,
  JoinUsText,
  TitleBox,
  TitleInColor,
  FistPart,
  SecondPart,
  OrText,
  JoinText,
  PageContent,
  CellphoneImg,
  DirtInHandsImg,
} from './OurApp_style.js';

import Buttom from '../Global/Buttom';
import vector from '../../assets/OurApp/Vector.svg';

const OurApp = () => {
  return (
    <Container>
      <TitleBox>
        <FistPart>
          <Title>NOSSO</Title>
        </FistPart>
        <SecondPart>
          <TitleInColor>APP</TitleInColor>
        </SecondPart>
      </TitleBox>

      <PageContent>
        <SubTitle> O Aplicativo do agronegócio!</SubTitle>
        <Text>
          Conheça o aplicativo para o seu negócio, exclusivo para Clientes dos
          Franqueados Goplan. Solicite uma visita do seu Franqueado, receba
          atualizações do dólar, da previsão do tempo para sua cidade e as
          cotações das melhores commoditites.
        </Text>
        <CellphoneImg>
          <div />
        </CellphoneImg>
        <DirtInHandsImg>
          <div />
        </DirtInHandsImg>
        <BottomPage>
          {/* TODO: Aply link to login page in real aplication */}
          <Buttom
            content="Já tenho uma conta"
            type="white"
            mobileMargin={'0px auto 10px auto'}
            desktopMargin={'0px 10px 10px 40px;'}
            textPadding={'0px 40px 0px 40px;'}
          />
          <JoinUsText>
            <OrText>ou</OrText>
            <JoinText>
              Cadastre-se <img src={vector} alt="Arrow to right" />
            </JoinText>
          </JoinUsText>
        </BottomPage>
      </PageContent>
    </Container>
  );
};

export default OurApp;
